const CheckForHighlight = (page, data) => {
   let modData = data.map(edges => {
    edges.node.highlight = false;
        if (edges.node.highlightadPSida === page) {
            edges.node.highlight = true;
            return edges
        } else {
            edges.node.highlight = false;
            return edges
        }
    })
    return modData;
}

export default CheckForHighlight;