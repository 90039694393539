import { graphql, useStaticQuery } from "gatsby";

export const EnheterQuery = () => {
  let query = useStaticQuery(
    graphql`
      query {
        allContentfulEnheter(sort: { fields: [namn], order: ASC }) {
          edges {
            node {
              updatedAt(formatString: "YY-MM/DD  h:mm:ss")
              node_locale
              seasonClosed
              slug
              pekaMotSlug
              namn
              sida
              siteDescription
              highlightadPSida
              kvarglmtMail
              huvudBild {
                fluid(quality: 30) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              huvudBild2 {
                fluid(quality: 30) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              skord
            }
          }
        }
      }
    `
  );

  return query.allContentfulEnheter
};
